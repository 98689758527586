@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

html {
  scrollbar-gutter: stable;
}
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  font-family: "Josefin Sans", "Poppins", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: overlay;
  --swiper-navigation-size: 24px;
  --swiper-navigation-sides-offset: 8px;
  --swiper-pagination-bullet-inactive-color: #e0e0e0;
}

.swiper-wrapper {
  padding-bottom: 32px;
}

@media screen and (max-width: 992px) {
  body {
    --swiper-navigation-sides-offset: 0px;
  }
  .swiper-wrapper {
    padding-bottom: 28px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #2d15b5;
}

#defaultCanvas0 {
  display: none;
}

.flex-absolute-centered {
  position: absolute;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
